$('.content-popup .fa-times').click(function(){
  $('.content-popup').hide();
});

$('.open_modal').click(function(){
  $('.content-popup').show();
});

$('.lost_found-wrap .cancel').click(function(){
  $('.popup-cancel').show();
});

$('.popup-cancel .close-popup').click(function(){
  $('.popup-cancel').hide();
});

$('.popup-change .close-popup').click(function(){
  $('.popup-change').hide();
});

$(document).on('click', '.intro_popup .close', function(){
  $('.intro_popup').hide();
});

$(document).on('click', '.toolpopup .fa-info-circle', function(){
  $(this).parent().find('.popup').show();
});

$(document).on('click', '.toolpopup .close-popup', function(){
  $('.toolpopup .popup').hide();
});

$('#ship-to-different-address-checkbox').change(function(){
  if($(this).prop('checked') == false) {
    $('.shipping_fields').hide();
  } else {
    $('.shipping_fields').show();

    $('input[name="billing_first_name"]').val($('input[name="shipping_first_name"]').val());
    $('input[name="billing_last_name"]').val($('input[name="shipping_last_name"]').val());
    $('input[name="billing_company"]').val($('input[name="shipping_company"]').val());
    $('input[name="billing_address_1"]').val($('input[name="shipping_address_1"]').val());
    $('input[name="billing_address_2"]').val($('input[name="shipping_address_2"]').val());
    $('input[name="billing_address_3"]').val($('input[name="shipping_address_3"]').val());
    $('input[name="billing_postcode"]').val($('input[name="shipping_postcode"]').val());
    $('input[name="billing_city"]').val($('input[name="shipping_city"]').val());
  }

  checkFields();
});

$(document).on('click', '.lostfound_v2 .btn.next, .lostfound_v2 .btn.prev', function(){
  if($(this).hasClass('disable') === false) {  
    var goTo = $(this).attr('data-goto');

    if(goTo.length > 0) {
      $('.tab').hide();
      $('.step').removeClass('active');
      $('.tab#' + goTo).show();
      $('.step[data-step="'+ goTo +'"]').addClass('active');

      $('html, body').animate({
          scrollTop: $(".steps").offset().top - 150
       }, 300);
    }
  }
});

$('.lostfound_v2 .shipping_type .type').click(function(){
  $('.lostfound_v2 .shipping_type .type').removeClass('active');

  $('.lostfound_v2 .summary').show();
  $('.lostfound_v2 .shipmenttype_wrap').show();
  $('.lostfound_v2 .shipmenttype').html($(this).attr('data-type') + '<span class="price"></span>');

  $('input[name="send_type"]').val($(this).attr('data-type'));
  $(this).addClass('active');
  total_price();
});

/////////////// 
function lostfound_price2(place_id) {
    var place = place_id;
    var url = $('.lostfound_v2').attr('data-url');
    var pageid = $('.lostfound_v2').attr('data-post');

    if(place.length > 0) {
      $('.shipping_type').show();
      $('.shipping_type .lds-ellipsis_lf').show();
      $('.shipping_type .after_load').hide();

      $.ajax({
          type: "POST",
          url: url + '/ajax/call.php',
          data: {
            placeID: place,
            pageid: pageid,
            type: 'lostandfound_v2'
          },                
          success: function(val){
            console.log(val);
            if(val != 'false') {
              $('.shipping_type .lds-ellipsis_lf').hide();
              $('.shipping_type .after_load').show();

              if($('input[name="content"]').val().length > 0) {
                $('.tab.first .btn').removeClass('disable');
              } else {
                $('.tab.first .btn').addClass('disable');
              }

              var data = jQuery.parseJSON(val);
              var v = data.info;

              if($('#ship-to-different-address-checkbox').prop('checked') == true) {
                $('select[name="shipping_country"] option').each(function(){
                  var code = $(this).val();

                  if(code === v.country_code) {
                    $(this).prop('selected', true)
                  }
                });
              } else {
                $('select[name="shipping_country"] option').each(function(){
                  var code = $(this).val();

                  if(code === v.country_code) {
                    $(this).prop('selected', true)
                  }
                });
              }

              if(typeof v.courier_true !== 'undefined'){
                if(v.courier_true == 'false') {
                  $('.modal-koerier-big').show();
                } else {
                  $('.modal-koerier-big').hide();
                }
              }

              if(typeof v.region.length !== 'undefined'){

                $('input[name="region"]').val(v.region);

                if(v.region === 'Europe' || v.region === 'europe') {
                  tax = 1.21;

                  if(v.country == 'Switzerland') {
                    tax = 1;
                  } else if(v.country == 'Norway') {
                    tax = 1;
                  } else if(v.country == 'Serbia') {
                    tax = 1;
                  } else if(v.country == 'Bosnia and Herzegovina') {
                    tax = 1;
                  }

                  $('.lostfound_v2').attr('data-tax', tax);
                  $('.lostfound_v2 .approximate_values').hide();

                  console.log(v.country);
                  if(v.country == 'Bosnia and herzegovina' || v.country == 'Iceland' || v.country == 'Iceland' || v.country == 'Liechtenstein' || v.country == 'Norway' || v.country == 'Serbia' || v.country == 'Switzerland') {
                    $('.lostfound_v2 .approximate_values').show();
                  }
                } else {
                  $('.lostfound_v2').attr('data-tax', '1');
                  $('.lostfound_v2 .approximate_values').show();
                  $('.tab.first .btn').addClass('disable');
                  tax = 1;
                }
              }

              if(data.lost.show_prio == false) {
                $('.type.priority').hide();
                $('.type.economy').click();
              } else {
                $('.type.priority').show();
              }

              if(data.lost.show_econ == false) {
                $('.type.economy').hide();
                $('.type.priority').click();

              } else {
                $('.type.economy').show();
              }

              if(typeof data.lost.econ_days !== 'undefined') {
                $('.econ_days').html(data.lost.econ_days);
              }

              if(typeof data.lost.econ_price !== 'undefined') {
                $('.econ_price').html(parseFloat(data.lost.econ_price * tax).toFixed(2));
                $('.type.economy').attr('data-price', parseFloat(data.lost.econ_price * tax).toFixed(2));
              }

              if(typeof data.lost.prio_days !== 'undefined') {
                $('.prio_days').html(data.lost.prio_days);
              }

              if(typeof data.lost.prio_price !== 'undefined') {
                $('.prio_price').html(parseFloat(data.lost.prio_price * tax).toFixed(2));
                $('.type.priority').attr('data-price', parseFloat(data.lost.prio_price * tax).toFixed(2));
              }

              total_price();

            } else {
              $('.shipping_type').hide();
            }
          }
      });
    }
}

$('input[name="content"], input[name="approximate_values"]').keyup(function(){
  if($('input[name="content"]').val().length > 1) {
    if($('.shipping_type').css('display') == 'block') {

      if($('.box.approximate_values').css('display') == 'block') {
        if($('input[name="approximate_values"]').val().length > 1) {
          $('.tab.first .btn').removeClass('disable');
        } else {
          $('.tab.first .btn').addClass('disable');
        }
      } else {
        $('.tab.first .btn').removeClass('disable');
      }

    } else {
      $('.tab.first .btn').addClass('disable');
    }
  } else {
    $('.tab.first .btn').addClass('disable');
  }
});

google.maps.event.addListener(autocomplete, 'place_changed', function() {
  var place = autocomplete.getPlace();
  if (place.place_id) {
    $('input[name="destination"]').val(place.formatted_address);
    var place_id = place.place_id;

    if(place.formatted_address > 0) {
      $('.destination_summary_row').show();
      $('.destination_value').html(place.formatted_address);
    } else {
      $('.destination_summary_row').hide();
    }

    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .destination_wrap').show();
    $('.lostfound_v2 .destination_value').html(place.formatted_address);

    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .shipmenttype_wrap').show();
    $('.lostfound_v2 .shipmenttype').html($('.lostfound_v2 .shipping_type .type.active').attr('data-type') + '<span class="price"></span>');
    $('input[name="send_type"]').val($('.lostfound_v2 .shipping_type .type.active').attr('data-type'));

    lostfound_price2(place_id);
  } else {
    $('.lostfound_v2 .destination_wrap').hide();
  }
});

$('.form_lostfound2 #shipping_country').change(function(){
  $('.popup-change').show();
});


$('.form_lostfound2 #shipping_country').change(function(){
  var suggest = $(this).val();

  suggest = $('#shipping_country option[value="'+ suggest +'"]').text();

  
  $.getJSON('https://maps.googleapis.com/maps/api/geocode/json?address='+suggest+'&key=AIzaSyDLbna3zJDQXC0eS0U7VprmNl4g7nyXpDA', null, function (data) {
      if (data.results[0].place_id.length > 0) {
          var place = data.results[0];
          $('input[name="lf_destination"]').val(place.formatted_address);
          $('input[name="destination"]').val(place.formatted_address);
          var place_id = place.place_id;

          if(place.formatted_address > 0) {
            $('.destination_summary_row').show();
            $('.destination_value').html(place.formatted_address);
          } else {
            $('.destination_summary_row').hide();
          }

          $('.lostfound_v2 .summary').show();
          $('.lostfound_v2 .destination_wrap').show();
          $('.lostfound_v2 .destination_value').html(place.formatted_address);

          $('.lostfound_v2 .summary').show();
          $('.lostfound_v2 .shipmenttype_wrap').show();
          $('.lostfound_v2 .shipmenttype').html($('.lostfound_v2 .shipping_type .type.active').attr('data-type') + '<span class="price"></span>');
          $('input[name="send_type"]').val($('.lostfound_v2 .shipping_type .type.active').attr('data-type'));

          lostfound_price2(place_id);

          if (!place.geometry) {
            return;
          }
      }
  });
});

if($('input[name="place_id"]').length > 0) {
  if($('input[name="place_id"]').val().length > 0) {
    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .destination_wrap').show();
    $('.lostfound_v2 .destination_value').html($('input[name="lf_destination"]').val());

    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .shipmenttype_wrap').show();
    $('.lostfound_v2 .shipmenttype').html($('.lostfound_v2 .shipping_type .type.active').attr('data-type') + '<span class="price"></span>');

    lostfound_price2($('input[name="place_id"]').val());
  }
}

function lf_insurance_calc(){
  var tax = $('.product.woocommerce').attr('data-tax');

  if ($('select[name="insurance_type"]').val() != 'free') {
    var value_raw = $('.lostfound_v2 select[name="insurance_type"]').val();
    var shipment_value = parseFloat(value_raw).toFixed(2);
    var insurance = shipment_value;
    var insurance_text = $('select[name="insurance_type"] option[value="'+ value_raw +'"]').text();

    $('input[name="insurance"]').val(insurance);
    $('input[name="insurance_raw"]').val(insurance_text);

    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .insurance_wrap').show();
    $('.lostfound_v2 .insurancevalue').html(insurance_text);

  } else {
    $('input[name="insurance"]').val(0);
    $('.lostfound_v2 .insurance_wrap').hide();
  }

  total_price();
}

$('.lostfound_v2 select[name="insurance_type"]').change(function(){
  lf_insurance_calc();
});

if ($('input[name="add_insurance"]').is(':checked')) {
  lf_insurance_calc();
}

$('.lostfound_v2 input[name="content"]').change(function(){
  var input = $(this).val();

  if(input.length > 0) {
    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .content_wrap').show();
    $('.lostfound_v2 .contentvalue').html(input);
  } else {
    $('.lostfound_v2 .content_wrap').hide();
  }
});

if($('.lostfound_v2 input[name="content"]').length > 0) {
  if($('.lostfound_v2 input[name="content"]').val().length > 0) {
    $('.lostfound_v2 .summary').show();
    $('.lostfound_v2 .content_wrap').show();
    $('.lostfound_v2 .contentvalue').html($('.lostfound_v2 input[name="content"]').val());
    $('.tab#step2 .btn').removeClass('disable');
  }
} 

var timeout;
$('#step2 input').keyup(function(){

  if(timeout) {
      clearTimeout(timeout);
      timeout = null;
  }

  timeout = setTimeout(checkFields, 500);
});

if($('input[name="billing_first_name"]') != undefined) {
  if($('input[name="billing_first_name"]').val() != undefined && $('input[name="billing_first_name"]').val().length > 0) {
    checkFields();
  }
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function checkFields() {
  var validate = 'true';
  var fields = $('#step2 .payment-details input');
  
  if($('#shipping_check').prop('checked') == false) {
    fields = $('#step2 input');
  }  

  fields.each(function(){
    var attr = $(this).attr('required');

    if (typeof attr !== typeof undefined && attr !== false) {
      // Element has this attribute
      if($(this).val() == 0) {
        validate = 'false';
        return false;
      }

      if($(this).attr('type') == 'email') {
        val_email = validateEmail($(this).val());
        if(val_email == false) {
          $(this).addClass('error');
          validate = 'false';
          return false;
        } else {
          $(this).removeClass('error');
        }
      }
    }
  });

  if(validate == 'true') {
    $('.tab#step2 .btn').removeClass('disable');
  } else {
    $('.tab#step2 .btn.next').addClass('disable');
  }
}

function total_price() {
  var tax = $('.product.woocommerce').attr('data-tax');

  if(tax == '1') {
    var price = parseFloat($('.shipping_type .type.active .price_wrap').text());
  } else {
    var price = parseFloat($('.shipping_type .type.active .price_wrap').text() / 121 * 100).toFixed(2);
  }

  if(price > 0) {
    var insurance = parseFloat($('input[name="insurance"]').val());
    var totalPrice = parseFloat((price * tax) + (insurance)).toFixed(2);

    $('.shipmenttype span.price').html(' (&euro;' + parseFloat(price * tax).toFixed(2) + ')');
    $('input[name="ship_type"]').val($('.shipmenttype').text());
    $('input[name="total_price"]').val(price);

    $('.total_wrap').show();
    $('.totalvalue').html('&euro;'+totalPrice);
    $('input[name="total_raw"]').val('€'+totalPrice)
  } else {
    $('.total_wrap').hide();
  }
}

$('.form_lostfound2').on('submit', function(e) { 
  $('#place_order').hide();
});